import { OrderSuccessParams } from "@/utils/types";
import slugify from "slugify";

export const ROUTES = {
  // Admin
  login: `/admin/login`,
  settings: `/admin/settings`,
  bookings: `/admin/bookings/list`,
  createBooking: `/admin/bookings/create`,
  editBooking: (id: number) => `/admin/bookings/edit/${id}`,
  promoCodes: `/admin/promo-codes`,
  subscribers: `/admin/subscribers`,

  // Web
  home: "/development",
  howItWorks: `/how-it-works`,
  features: `/features`,
  blogs: `/blogs`,
  blog: (slug: string) => `/blog/${slugify(slug)}`,
  aboutUs: "/about-us",
  contactUs: "/contact-us",
  faq: `/faqs`,

  // Policies
  privacyPolicy: `/privacy-policy`,
  termsAndConditions: `/terms-and-conditions`,

  // Booking
  booking: `/booking`,
  checkout: (stopId: number) => `/checkout/${stopId}`,
  orderSuccess: (params?: Partial<OrderSuccessParams>) => {
    if (!params) return `/order-success`;

    const searchParams = new URLSearchParams();
    for (const key in params) {
      searchParams.append(key, (<any>params)[key]);
    }

    return `/order-success/?${searchParams.toString()}`;
  },

  // External
  whatsapp: (number: string) => `https://wa.me/${slugify(number, { remove: /\s/g })}?text=Hello,`,
  instagram: "https://www.instagram.com/skyygo5",
};
