export const DATE_FORMAT = {
  FULL: "dd MMM yyyy, h:mm a",
  DATE: "dd MMM yyyy",
  TIME: "hh:mm a",
};

export const TWENTY_FOUR_HOURS_IN_MS = 1000 * 60 * 60 * 24;
export const ONE_HOUR_IN_MS = 1000 * 60 * 60;
export const FIVE_MINUTES_IN_MS = 1000 * 60 * 5;
export const TEN_MINUTES_IN_MS = 1000 * 60 * 10;
