import { isDev, isServer } from "./environment";

/**
 * Register service.
 * @description Stores instances in `global` to prevent memory leaks in development.
 * @param {string} name Service name.
 * @param {() => T} initFn Function returning the service instance.
 * @returns {T} Service instance.
 */
export const registerService = <T>(name: string, initFn: () => T): T => {
  if (isDev()) {
    const _global: Record<string, any> = isServer() ? global : globalThis;

    if (!(name in _global)) {
      // Use 'as any' assertion to address the TypeScript error
      _global[name] = initFn();
      console.log(`Registered service (${isServer() ? "server" : "client"}): ${name}`);
    }

    // Use 'as any' assertion to address the TypeScript error
    return _global[name];
  }

  return initFn();
};
