import { QueryClient } from "@tanstack/react-query";
import { registerService } from "@/utils/register-service";
import { AxiosError } from "axios";
import { TWENTY_FOUR_HOURS_IN_MS } from "@/constants/time-formats";

export const queryClient = registerService("queryClient", () => createQueryClient());

export function createQueryClient() {
  return new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnMount: true,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        staleTime: TWENTY_FOUR_HOURS_IN_MS,
        gcTime: TWENTY_FOUR_HOURS_IN_MS,
        retry(failureCount, error) {
          console.log("createQueryClient error ==> ", error);

          if (error.message.includes("401") || (error instanceof AxiosError && error.response?.status === 401)) {
            return failureCount < 3;
          }

          return false;
        },
      },
    },
  });
}
